import React from 'react';
import './CalculatorLeftSection.scss';

const CalculatorLeftSection = () => {
  return (
    <div className="Grid-cell CalculatorLeftSection-leftSide CalculatorLeftSection-referral">
      <h4 className="CalculatorLeftSection-titleRow CalculatorLeftSection-bold">
        <span>
          ¡TU AMIGO TE HA REGALADO UN 10% DE DESCUENTO EN TU PRIMER PRÉSTAMO!{' '}
          <span role="img" aria-label="tada">
            🎉
          </span>
        </span>
      </h4>

      <h3 className="CalculatorLeftSection-titleCaption">
        <span>Válido solo durante 7 días</span>
      </h3>

      <h6 className="CalculatorLeftSection-description">
        <span>
          ¡Bienvenid@ a QuéBueno! Hemos activado tu descuento, y se aplicará automáticamente al
          solicitar tu préstamo. No necesitas hacer nada más que elegir la cantidad que necesitas y
          el plazo de devolución.
        </span>

        <span>
          <span role="img" aria-label="pushpin">
            📌
          </span>{' '}
          ¿Cómo funciona?
        </span>

        <span>
          {' '}
          <span role="img" aria-label="one">
            1️⃣
          </span>{' '}
          Selecciona el importe y el plazo.
        </span>

        <span>
          <span role="img" aria-label="two">
            2️⃣
          </span>{' '}
          Haz clic en "Solicitar préstamo" y sigue los pasos.
        </span>

        <span>
          <span role="img" aria-label="three">
            3️⃣
          </span>{' '}
          Recibe el dinero en tu cuenta en solo 10 minutos.
        </span>

        <span>
          ¡Aprovecha esta oportunidad y consigue tu préstamo con descuento ahora!{' '}
          <span role="img" aria-label="rocket">
            🚀
          </span>
        </span>
      </h6>
    </div>
  );
};

CalculatorLeftSection.propTypes = {};

export default CalculatorLeftSection;
