import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import person from '../../skin/images/landing/person.png';

import './CalculatorLeftSection.scss';

const CalculatorLeftSection = ({ user, details, zeroInterestsForNewUsersFlag }) => {
  const showZeroInterests = () => {
    return zeroInterestsForNewUsersFlag && user && !user.returning;
  };

  return (
    <div className="Grid-cell CalculatorLeftSection-leftSide">
      <h2 className="CalculatorLeftSection-titleRow">{details.title}</h2>

      {showZeroInterests() && (
        <div className="CalculatorLeftSection-titleRow CalculatorLeftSection-subTitle">
          <span className="CalculatorLeftSection-hugeText">0%</span>
          <span>INTERESES</span>
        </div>
      )}
      <LazyLoadImage
        wrapperClassName="CalculatorLeftSection-person"
        src={details.image}
        alt="person image"
      />
    </div>
  );
};

CalculatorLeftSection.defaultProps = {
  details: {
    title: (
      <span>
        PIDE TU PRÉSTAMO AHORA Y RECÍBELO EN{' '}
        <span className="CalculatorLeftSection-semibold">10 MINUTOS</span>
      </span>
    ),
    image: person,
  },
};

CalculatorLeftSection.propTypes = {
  user: PropTypes.object,
  details: PropTypes.shape({
    title: PropTypes.node,
    image: PropTypes.string,
  }),
  openModal: PropTypes.func,
  onRecalculateData: PropTypes.func,
  handleDiscount: PropTypes.func,
};

export default CalculatorLeftSection;
