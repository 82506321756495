import PropTypes from 'prop-types';
import React from 'react';
import { setCookie } from '../../../shared/libs/cookies';
import Button from '../../Button';
import './DiscountCode.scss';
import Confetti from 'react-confetti';

const PROMO_CODE = `QB${new Date().getFullYear()}`;

const DiscountCode = ({ handleClose }) => {
  const handleClick = () => {
    setCookie('popup_promo_code', PROMO_CODE);
    handleClose();
  };
  return (
    <div className="DiscountCode">
      <Confetti />
      <div className="DiscountCode-info">
        <p className="DiscountCode-title">Aqui tienes tu codigo</p>
        <p className="DiscountCode-code">{PROMO_CODE}</p>
      </div>
      <div className="DiscountCode-button">
        <Button primary onClick={handleClick}>
          Gracias!
        </Button>
      </div>
    </div>
  );
};

DiscountCode.defaultProps = {};

DiscountCode.propTypes = {
  handleClose: PropTypes.func,
};

export default DiscountCode;
