import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Calculator from '../Calculator';
import person from '../../skin/images/landing/person.png';
import * as apiService from '../../shared/api.service';
import './CalculatorSection.scss';
import CalculatorLeftSection from '../CalculatorLeftSection/CalculatorLeftSection';
import CalculatorLeftReferralSection from '../CalculatorLeftSection/CalculatorLeftReferralSection';
import { useReferral } from '../../shared/customHooks/useReferral';

const CalculatorSection = ({ user, details, openModal, onRecalculateData, handleDiscount }) => {
  const { isReferral } = useReferral();
  const [zeroInterestsForNewUsersFlag, setZeroInterestsForNewUsersFlag] = useState(null);
  const { t: token } = typeof window !== `undefined` && queryString.parse(window.location.search);

  useEffect(() => {
    const getZeroIntestsForNewUsersFlag = async () => {
      const { data, status } = await apiService.getZeroInterestsForNewUsersFlag();
      if (status === 200) {
        setZeroInterestsForNewUsersFlag(data.value);
      }
    };
    getZeroIntestsForNewUsersFlag();
  }, []);

  return (
    <div className="CalculatorSection Grid">
      <div className="CalculatorSection-container">
        <div className="Grid-cell CalculatorLeftSection-leftSide">
          {!isReferral && (
            <CalculatorLeftSection
              user={user}
              details={details}
              zeroInterestsForNewUsersFlag={zeroInterestsForNewUsersFlag}
            />
          )}
          {isReferral && <CalculatorLeftReferralSection />}
        </div>
        <div className="Grid-cell CalculatorSection-rightSide">
          <div className="">
            <div id="calculator" className="CalculatorSection-calculatorWrapper">
              <Calculator
                maxAmount={user.max_amount}
                interestRate={user.interest_rate}
                initialAmount={user && user.max_amount}
                isReturningUser={user.returning}
                token={token}
                zeroInterestsForNewUsersFlag={zeroInterestsForNewUsersFlag}
                onRecalculateData={onRecalculateData}
                handleDiscount={handleDiscount}
              />
            </div>
            <div className="CalculatorSection-extraInfo">
              <div className="CalculatorSection-infoLinks">
                <a className="CalculatorSection-infoLink" onClick={openModal}>
                  INFORMACIÓN SOBRE EL PRÉSTAMO
                </a>
                {/* <a className="CalculatorSection-infoLink" href="#devolucion">
                  DEVOLUCIÓN
                </a>
                <a className="CalculatorSection-infoLink" href="#prorroga">
                  PRÓRROGA
                </a>
                <a className="CalculatorSection-infoLink" href="#deuda">
                  POSICIONES DEUDORAS VENCIDAS
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CalculatorSection.defaultProps = {
  details: {
    title: (
      <span>
        PIDE TU PRÉSTAMO AHORA Y RECÍBELO EN{' '}
        <span className="CalculatorSection-semibold">10 MINUTOS</span>
      </span>
    ),
    image: person,
  },
};

CalculatorSection.propTypes = {
  user: PropTypes.object,
  details: PropTypes.shape({
    title: PropTypes.node,
    image: PropTypes.string,
  }),
  openModal: PropTypes.func,
  onRecalculateData: PropTypes.func,
  handleDiscount: PropTypes.func,
};

export default CalculatorSection;
