import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { round2Dec, calcDiscount } from '../../../../shared/libs/maths';
import { useReferral } from '../../../../shared/customHooks/useReferral';
import './ReferralDiscount.scss';

/* eslint-disable jsx-a11y/label-has-associated-control */
const ReferralDiscount = ({ amount, interests }) => {
  const { discount: discountReferral, isExpired, referrer } = useReferral();
  const [discount, setDiscount] = useState(discountReferral);

  useEffect(() => {
    if (discountReferral) {
      setDiscount(
        calcDiscount(discountReferral.type, interests, discountReferral.amount, interests)
      );
    }
  }, [discountReferral, amount, interests]);

  return (
    <div className="ReferralDiscount">
      {!isExpired && (
        <span className="ReferralDiscount-data" data-testid="discount-data">
          {referrer} te ha regalado un dto. de -{round2Dec(discount)}€
        </span>
      )}

      {isExpired && (
        <span
          className="ReferralDiscount-data ReferralDiscount-expired"
          data-testid="discount-data-expired"
        >
          Lo sentimos, tu código ha caducado.
        </span>
      )}
    </div>
  );
};

ReferralDiscount.propTypes = {
  amount: PropTypes.number.isRequired,
  interests: PropTypes.number.isRequired,
};

export default ReferralDiscount;
