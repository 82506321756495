export const round2Dec = (value) => Math.round(value * 100) / 100;

export const calcDiscount = (type, interest, amount, interests) => {
  if (type?.toUpperCase() === 'RATE_DISCOUNT') {
    return round2Dec((interest * amount) / 100);
  } else if (type?.toUpperCase() === 'FIX_DISCOUNT' && interests >= amount) {
    return amount;
  } else if (type?.toUpperCase() === 'FIX_DISCOUNT' && interests < amount) {
    return interests;
  }
  return 0;
};
